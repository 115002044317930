<template>
  <div class="more">
    <div class="item">
      <div class="title">村口信息</div>
      <div class="content">
        <img :src="a" class="icon-img">
        <p>快递查询</p>
      </div>
      <div class="content">
        <img :src="b" class="icon-img">
        <p>快递查询</p>
      </div>
      <div class="content">
        <img :src="c" class="icon-img">
        <p>快递查询</p>
      </div>
    </div>
    <div class="item">
      <div class="title">村口信息</div>
      <div class="content">
        <img :src="a" class="icon-img">
        <p>快递查询</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return{
      a:require('@/assets/img/icon/1.png'),
      b:require('@/assets/img/icon/2.png'),
      c:require('@/assets/img/icon/3.png'),
      d:require('@/assets/img/icon/4.png'),
    }
  }
}
</script>

<style lang="less" scoped>
.more{
  background: #F7F8F9;
  height: 100%;
  width: 100%;
  padding: 0.32rem 0 0;
  .flex{
    display: flex;
  }
  .icon-img{
    display: block;
    width: 0.88rem;
    height: 1.06rem;
    margin: 0 auto;
  }
  .content{
    width: calc(25%);
    display: inline-block;
    vertical-align: middle;
    padding: 0.23rem 0;
    text-align: center;
    p{
      font-size: 0.26rem;
      margin: 0;
    }
  }
  .item{
    background: #fff;
    margin-bottom: 0.5rem;
    .title{
      padding: 0.3rem;
      font-size: 0.32rem;
      color: #000;
    }
  }
}
</style>